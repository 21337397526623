import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from 'primereact/checkbox';
import { useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import ProductService from "../../services/products/products.service";
import { ProductDetailModel } from "../../components/admin/products";
import { Toast, ToastMessage } from "primereact/toast";
import { InputNumber } from 'primereact/inputnumber';

interface EditProductProps {
    isUpdate: boolean;
    handleOnSuccess: any;
    updateProductInfo: ProductDetailModel | undefined | null;
    subCate?:any;
}

const ProductInfo: React.FC<EditProductProps> =  ({ isUpdate, handleOnSuccess, updateProductInfo, subCate }) => {
    const { t } = useTranslation();
    const toast = useRef<Toast>(null);
    const [categories, setCategories] = useState<any>();
    const [subCategories, setSubCategories] = useState<any>(subCate);
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [selectSubCategory, setSelectedSubCategory] = useState<any>();    
    const [filteredSubCategories, setFilteredSubCategories] = useState<any>();
    const [mesuretype, setMessuretype] = useState<string>("");

    const showMessage = (label: string, message: string, severity: ToastMessage['severity']) => {
        toast.current?.show({ severity: severity, summary: label, detail: message, life: 3000 });
    }
    const formik: any = useFormik({
        initialValues: {
            name: "",
            vendorID: 1,
            categoryID: null,
            subCategoryID: null,
            code: "",
            weight: null,
            description: "",
            usage: "",
            minOrderQty: null,
            unitPrice: null,
            gst: null,
            hsN_Code: "",
            subscriptionAvailable: false,
            activeStartDate: null,
            activeEndDate: null,
            leadTime: null,
            canExchange: false,
            canReturn: false,
            courierAvailable: false,
            shelfLife: "",
            inStock: 1,
            isDeleted: false,
            orderMultiples: ""
        },
        validateOnChange: false,  
        validate: (data: any) => {

            let errors: any = {};

            if (!data.categoryID) {
                errors.categoryID = t("category_is_required");
            }

            if (!data.subCategoryID) {
                errors.subCategoryID = t("sub_category_is_required");
            }

            if (!data.vendorID) {
                errors.vendorName = t("vendor_is_required");
            }

            if (!data.measurementTypeID) {
                errors.measurementTypeID = t("measurement_type_required");
            }

            if (!data.name) {
                errors.name = t("name_required");
            }

            if (!data.unitPrice) {
                errors.unitPrice = t("unit_type_is_required");
            }

            if (!data.weight) {
                errors.weight = t("weight_type_is_required");
            }

            if (!data.minOrderQty) {
                errors.minOrderQty = t("min_qty_is_required");
            }

            if (!data.gst) {
                errors.gst = t("gst_required");
            }
            if (!data.orderMultiples) {
                errors.orderMultiples = t("OrderMultiples is Required");
            }

            return errors;
        },
        onSubmit: async(data: any) => {
            const errors = await formik.validateForm(data);
            
            if (Object.keys(errors).length > 0) {
                return;
            }
            var product = {
               "productID": data.productID,
                "vendorID": data.vendorID,
                "subCategoryID": data.subCategoryID,
                "measurementTypeID": data.measurementTypeID,
                "name": data.name,
                "unitPrice": data.unitPrice,
                "code": data.code,
                "description": data.description,
                "usage": data.usage,
                "weight": data.weight,
                "minOrderQty": data.minOrderQty,
                "orderMultiples": data.orderMultiples,
                "gst": data.gst,
                "hsN_Code": data.hsN_Code,
                "subscriptionAvailable": data.subscriptionAvailable,
                "activeStartDate": data.activeStartDate,
                "activeEndDate": data.activeEndDate,
                "leadTime": data.leadTime,
                "canExchange": data.canExchange,
                "canReturn": data.canReturn, 
                "courierAvailable": data.courierAvailable,
                "shelfLife": data.shelfLife,
                "inStock": data.inStock
            };
            let response = isUpdate?await ProductService.saveProduct(product):await ProductService.saveNewProduct(product);
            if(response.isSuccess){
                handleOnSuccess();
            }
            else {
                    showMessage('Update Products', response.message + ': Error in update product', 'error');
            }
        }
    });

    useEffect(()=>{
        fetchCategory();
        fetchSubCategory();
        if (isUpdate) {
            setProductData();
        }
    },[]);

    function fetchCategory() {
        ProductService.getCategory().then((res)=>{
            setCategories(res);
        });
    }

    function fetchSubCategory() {
        ProductService.getSubCategory().then((res)=>{
            setSubCategories(res);
        })
    }

    function setProductData() {
        if(updateProductInfo) {
            let categoryId: number = updateProductInfo.categoryID;
            setSelectedCategory(categoryId);
            const filterData: any = subCategories.filter((eachItem: any)=>eachItem.categoryID === categoryId)
            setFilteredSubCategories(filterData);
            formik.setValues({
                productID: updateProductInfo.productID,
                name: updateProductInfo.name,
                vendorID: updateProductInfo.vendorID,
                categoryID: updateProductInfo.categoryID,
                subCategoryID: updateProductInfo.subCategoryID,
                measurementTypeID: updateProductInfo.measurementTypeID,
                code: updateProductInfo.code,
                weight: updateProductInfo.weight,
                description: updateProductInfo.description,
                usage: updateProductInfo.usage,
                minOrderQty: updateProductInfo.minOrderQty,
                unitPrice: updateProductInfo.unitPrice,
                gst: updateProductInfo.gst,
                hsN_Code: updateProductInfo.hsN_Code,
                subscriptionAvailable: updateProductInfo.subscriptionAvailable,
                activeStartDate: updateProductInfo.activeStartDate,
                activeEndDate: updateProductInfo.activeEndDate,
                leadTime: updateProductInfo.leadTime,
                canExchange: updateProductInfo.canExchange,
                canReturn: updateProductInfo.canReturn,
                courierAvailable: updateProductInfo.courierAvailable,
                shelfLife: updateProductInfo.shelfLife,
                inStock: updateProductInfo.inStock,
                isDeleted: updateProductInfo.isDeleted,
                orderMultiples: updateProductInfo.orderMultiples
            });
        }
    }

    const measurementTypeItems = [
        { "name": "Litre", "measurementTypeID": 1 },
        { "name": "Kilogram", "measurementTypeID": 2 },
        { "name": "Grams", "measurementTypeID": 3 },
        { "name": "Pieces", "measurementTypeID":4}
    ];
    const trueFalseOptions = [
        { "name": "true", "value": true },
        { "name": "false", "value": false }
    ];
    const vendorItems = [
        { "userName": "F2MVendor1", "vendorID": 1 }
    ];

    const isFormFieldInvalid = (name: any) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name: any) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : "";
    };

    const categoryOnChange = (e: DropdownChangeEvent) => {
        setSelectedCategory(e.value);
        const filterSubCategories = subCategories.filter((eachItem: any)=>eachItem.categoryID == e.value);
        setFilteredSubCategories(filterSubCategories);
    }

    const subCategoryOnChange = (e: DropdownChangeEvent) => {
        setSelectedSubCategory(e.value);
    }

    const measureTypeOnChange = (e: DropdownChangeEvent) => {
        const selectedItem = measurementTypeItems.find(item => item.measurementTypeID === e.value);
        if (selectedItem) {
            setMessuretype(selectedItem.name);
        }
    };    

    return(
        <><Toast ref={toast} position="top-center" />
        <div className="col-12  flex align-items-stretch flex-wrap">
            <div className="flex flex-1 bg-white align-items-center justify-content-center">                
                <form onSubmit={formik.handleSubmit} >
                    <h5 className='border-round mb-3 order-top-color p-2 panel-header-text text-center'>
                        {isUpdate?t('update_product'):t('register_product')}
                    </h5>               
                    <div className="p-fluid formgrid grid">
                        <div className="col-12">
                            <div className="grid">
                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <div className="field">
                                        <label htmlFor="categoryID" className="label-semi-bold">{t('product_category')}<span className="required">*</span></label>
                                        <Dropdown
                                            id="categoryID"
                                            name="categoryID"
                                            // className={`pl-0 ${formik.values.categoryID === (null || undefined || "") ? 'p-invalid':""}`}
                                            className={`pl-0 ${classNames({ 'p-invalid': formik.errors.categoryID })}`}
                                            value={formik.values.categoryID}
                                            onChange={(e) => {
                                                formik.handleChange(e); 
                                                categoryOnChange(e)
                                            }}
                                            options={categories}
                                            optionLabel="name"
                                            optionValue="categoryID"
                                            placeholder="select category" />
                                        {getFormErrorMessage('categoryID')}
                                    </div>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <div className="field">
                                        <label htmlFor="subCategoryID" className="label-semi-bold">{t('product_sub_category')}<span className="required">*</span></label>
                                        <Dropdown
                                            id="subCategoryID"
                                            name="subCategoryID"
                                            // className={`pl-0 ${formik.values.subCategoryID === (null || undefined || "") ? 'p-invalid':""}`}
                                            className={`pl-0 ${classNames({ 'p-invalid': formik.errors.subCategoryID })}`}
                                            value={formik.values.subCategoryID}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                subCategoryOnChange(e);
                                            }}
                                            options={filteredSubCategories}
                                            optionLabel="name"
                                            optionValue="subCategoryID"
                                            placeholder="select sub category" />
                                        {getFormErrorMessage('subCategoryID')}
                                    </div>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <div className="field">
                                        <label htmlFor="vendorID" className="label-semi-bold">{t('vendor_name')}<span className="required">*</span></label>
                                        <Dropdown
                                            id="vendorID"
                                            name="vendorID"
                                            className={`pl-1 ${classNames({ 'p-invalid': formik.errors.vendorID })}`}
                                            value={formik.values.vendorID}
                                            onChange={formik.handleChange}
                                            options={vendorItems}
                                            optionLabel="userName"
                                            optionValue="vendorID"
                                            placeholder="select vendor" />
                                        {getFormErrorMessage('vendorID')}
                                    </div>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <div className="field">
                                        <label htmlFor="measurementTypeID" className="label-semi-bold">{t('measurement_Type')}<span className="required">*</span></label>
                                        <Dropdown
                                            id="measurementTypeID"
                                            name="measurementTypeID"
                                            className={`pl-1 ${classNames({ 'p-invalid': formik.errors.measurementTypeID })}` }
                                            value={formik.values.measurementTypeID}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                measureTypeOnChange(e);
                                            }}
                                            options={measurementTypeItems}
                                            optionLabel="name"
                                            optionValue="measurementTypeID"
                                            placeholder="select measurement type" />
                                        {getFormErrorMessage('measurementTypeID')}
                                    </div>
                                </div>

                                <div className="col-12 lg:col-6 mb-2 md:col-6 mb-3 px-3">
                                    <div className="field">
                                        <label htmlFor="name" className="label-semi-bold">{t('product_name')}<span className="required">*</span></label>
                                        <span className="p-input-icon-left">
                                            <InputText type="text" id="name" name="name" 
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                className={`pl-1 ${classNames({ 'p-invalid': formik.errors.name })}`} 
                                                placeholder={t('product_name')} />
                                        </span>
                                        {getFormErrorMessage('name')}
                                    </div>
                                </div>

                                <div className="col-12 lg:col-6 mb-2 md:col-6 mb-3 px-3">
                                    <label htmlFor="unitPrice" className="label-semi-bold">{t('unit_price')}<span className="required">*</span></label>
                                    <span className="p-input-icon-left">
                                        <InputText type="text" name="unitPrice" id="unitPrice"
                                            value={formik.values.unitPrice} 
                                            onChange={formik.handleChange} 
                                            className={`pl-1 ${classNames({ 'p-invalid': formik.errors.unitPrice })}`} 
                                            placeholder="Enter Unit Price" />
                                    </span>
                                    {getFormErrorMessage('unitPrice')}
                                </div>                                  

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <div className="field">
                                        <label htmlFor="usage" className="label-semi-bold">{t('usage')}</label>
                                        <span className="p-input-icon-left">                                                
                                            <InputTextarea autoResize id="usage" name="usage"
                                                value={formik.values.usage}
                                                onChange={formik.handleChange} 
                                                className={`pl-1 ${classNames({ 'p-invalid': formik.errors.usage })}`} 
                                                placeholder="Usage" rows={4} cols={30}/>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <div className="field">
                                        <label htmlFor="description" className="label-semi-bold">{t('description')}</label>
                                        <span className="p-input-icon-left">                                                
                                            <InputTextarea autoResize id="description" name="description"
                                                value={formik.values.description}
                                                onChange={formik.handleChange} 
                                                className={`pl-1 ${classNames({ 'p-invalid': formik.errors.description })}`}
                                                placeholder="Enter Description" rows={4} cols={30} />
                                        </span>
                                    </div>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <div className="field">
                                        <label htmlFor="code" className="label-semi-bold">{t('code')}</label>
                                        <span className="p-input-icon-left">
                                            <InputText type="text" id="code" name="code"
                                                value={formik.values.code}
                                                onChange={formik.handleChange} 
                                                className={`pl-1 ${classNames({ 'p-invalid': formik.errors.code })}`} 
                                                placeholder="Enter Code" />
                                        </span>
                                    </div>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="weight" className="label-semi-bold">{t('weight')}<span className="required">*</span></label>
                                    <span className="p-input-icon-left">
                                        <InputNumber
                                            name="weight"
                                            id="weight"
                                            value={formik.values.weight}
                                            onValueChange={e => formik.setFieldValue('weight', e.value)} 
                                            mode="decimal"
                                            placeholder={`Enter Weight in ${mesuretype}`}
                                            className={`pl-1 ${classNames({ 'p-invalid': formik.errors.weight })}`}
                                            maxFractionDigits={2} 
                                        />
                                    </span>
                                    {getFormErrorMessage('weight')}
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="minOrderQty" className="label-semi-bold">{t('min_order_qty')}<span className="required">*</span></label>
                                    <span className="p-input-icon-left">
                                    <InputNumber
                                            type="text" 
                                            name="minOrderQty" 
                                            id="minOrderQty" 
                                            value={formik.values.minOrderQty}
                                            onValueChange={e => formik.setFieldValue('minOrderQty', e.value)}
                                             mode="decimal"
                                            className={`pl-1 ${classNames({ 'p-invalid': formik.errors.minOrderQty })}`} 
                                            placeholder="Enter Min Quantity" 
                                            maxFractionDigits={2} 
                                        />
                                    </span>
                                    {getFormErrorMessage('minOrderQty')}
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="orderMultiples" className="label-semi-bold">{t('order_multiples')}<span className="required">*</span></label>
                                    <span className="p-input-icon-left">
                                        <InputText type="text" name="orderMultiples" id="orderMultiples" 
                                            value={formik.values.orderMultiples}
                                            onChange={formik.handleChange} 
                                            className={`pl-1 ${classNames({ 'p-invalid': formik.errors.orderMultiples })}`} 
                                            placeholder="Order Multiples" />
                                    </span>
                                    {getFormErrorMessage('orderMultiples')}
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="gst" className="label-semi-bold">{t('gst')}<span className="required">*</span></label>
                                    <span className="p-input-icon-left">
                                        <InputText type="text" name="gst" id="gst" 
                                            value={formik.values.gst}
                                            onChange={formik.handleChange} 
                                            className={`pl-1 ${classNames({ 'p-invalid': formik.errors.gst })}`} 
                                            placeholder="GST" />
                                    </span>
                                    {getFormErrorMessage('gst')}
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="hsN_Code" className="label-semi-bold">{t('hsn_code')}</label>
                                    <span className="p-input-icon-left">
                                        <InputText type="text" name="hsN_Code" id="hsN_Code" 
                                            value={formik.values.hsN_Code}
                                            onChange={formik.handleChange} 
                                            className={`pl-1 ${classNames({ 'p-invalid': formik.errors.hsN_Code })}`} 
                                            placeholder="HSN Code" />
                                    </span>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="subscriptionAvailable" className="label-semi-bold">{t('subscription_available')}</label>
                                    <span className="p-input-icon-left">
                                        <div className="field-checkbox col-12">
                                                <Checkbox
                                                    id="subscriptionAvailable"
                                                    name="subscriptionAvailable"
                                                    onChange={(e) => {
                                                        formik.setFieldValue('subscriptionAvailable', e.checked);
                                                    }}
                                                    checked={formik.values.subscriptionAvailable}
                                                />
                                                <label htmlFor="subscriptionAvailable">{t('subscription_available')} </label>
                                            </div>
                                    </span>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="activeStartDate" className="label-semi-bold">{t('active_start_date')}</label>
                                    <span className="p-input-icon-left">
                                        <InputText type="date" name="activeStartDate" id="activeStartDate" 
                                            value={formik.values.activeStartDate}
                                            onChange={formik.handleChange} 
                                            className={`pl-1 ${classNames({ 'p-invalid': formik.errors.activeStartDate })}`} 
                                            placeholder="Enter Active Start Date" />
                                    </span>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="activeEndDate" className="label-semi-bold">{t('active_end_date')}</label>
                                    <span className="p-input-icon-left">
                                        <InputText type="date" name="activeEndDate" id="activeEndDate" 
                                            value={formik.values.activeEndDate}
                                            onChange={formik.handleChange} 
                                            className={`pl-1 ${classNames({ 'p-invalid': formik.errors.activeEndDate })}`} 
                                            placeholder="Enter Active End Date" />
                                    </span>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="leadTime" className="label-semi-bold">{t('lead_time')}</label>
                                    <span className="p-input-icon-left">
                                        <InputText type="date" name="leadTime" id="leadTime" 
                                            value={formik.values.leadTime}
                                            onChange={formik.handleChange} 
                                            className={`pl-1 ${classNames({ 'p-invalid': formik.errors.leadTime })}`} 
                                            placeholder="lead time" />
                                    </span>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="canExchange" className="label-semi-bold">{t('can_exchange')}</label>
                                    <span className="p-input-icon-left">
                                        <div className="field-checkbox col-12">
                                                <Checkbox
                                                    id="canExchange"
                                                    name="canExchange"
                                                    onChange={(e) => {
                                                        formik.setFieldValue('canExchange', e.checked);
                                                    }}
                                                    checked={formik.values.canExchange}
                                                />
                                                <label htmlFor="canExchange">{t('can_exchange')} </label>
                                            </div>
                                    </span>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="canReturn" className="label-semi-bold">{t('can_return')}</label>
                                    <span className="p-input-icon-left">
                                        <div className="field-checkbox col-12">
                                                <Checkbox
                                                    id="canReturn"
                                                    name="canReturn"
                                                    onChange={(e) => {
                                                        formik.setFieldValue('canReturn', e.checked);
                                                    }}
                                                    checked={formik.values.canReturn}
                                                />
                                                <label htmlFor="canReturn">{t('can_return')} </label>
                                            </div>
                                    </span>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="courierAvailable" className="label-semi-bold">{t('courier_available')}</label>
                                    <span className="p-input-icon-left">
                                        <div className="field-checkbox col-12">
                                                <Checkbox
                                                    id="courierAvailable"
                                                    name="courierAvailable"
                                                    onChange={(e) => {
                                                        formik.setFieldValue('courierAvailable', e.checked);
                                                    }}
                                                    checked={formik.values.courierAvailable}
                                                />
                                                <label htmlFor="courierAvailable">{t('courier_available')} </label>
                                            </div>
                                    </span>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="shelfLife" className="label-semi-bold">{t('shelf_life')}</label>
                                    <span className="p-input-icon-left">
                                        <InputText type="text" name="shelfLife" id="shelfLife" 
                                            value={formik.values.shelfLife}
                                            onChange={formik.handleChange} 
                                            className={`pl-1 ${classNames({ 'p-invalid': formik.errors.shelfLife })}`} 
                                            placeholder="Shelf Life" />
                                    </span>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="inStock" className="label-semi-bold">{t('in_stock')}</label>
                                    <span className="p-input-icon-left">
                                        <InputText type="text" name="inStock" id="inStock" 
                                            value={formik.values.inStock}
                                            onChange={formik.handleChange} 
                                            className={`pl-1 ${classNames({ 'p-invalid': formik.errors.inStock })}`} 
                                            placeholder="In Stock" />
                                    </span>
                                </div>

                                <div className="col-12 md:col-6 lg:col-6 mb-3 px-3">
                                    <label htmlFor="isDeleted" className="label-semi-bold">{t('is_deleted')}</label>
                                    <span className="p-input-icon-left">
                                        {/* <InputText value={"0"} type="text" name="isDeleted" id="isDeleted" onChange={formik.handleChange} className={`pl-1 ${classNames({ 'p-invalid': formik.errors.isDeleted })}`} placeholder="Is Deleted" /> */}
                                        <div className="field-checkbox col-12">
                                                <Checkbox
                                                    id="isDeleted"
                                                    name="isDeleted"
                                                    onChange={(e) => {
                                                        formik.setFieldValue('isDeleted', e.checked);
                                                    }}
                                                    checked={formik.values.isDeleted}
                                                />
                                                <label htmlFor="isDeleted">{t('is_deleted')} </label>
                                            </div>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <Button type="submit" label="Save" className="right-align" > </Button>
                </form>
            </div>
        </div>
        </>  
    )
}

export default ProductInfo