import { Button } from "primereact/button";
import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Toast, ToastMessage } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import ProductInfo from "./product-info";
import { useTranslation } from "react-i18next";
import '../../assests/css/common/responsive-ui.css'

interface SearchPanelProps {
  onSearch: (query: string) => void;
  reloadPage: (value: boolean) => void;
  setLoading: (loading: boolean) => void;
}

const ProductSearch: React.FC<SearchPanelProps> = ({
  onSearch,
  reloadPage,
  setLoading
}) => {
  const { t } = useTranslation();
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const toast = useRef<Toast>(null);

  const handleSearch = () => {
    setLoading(true)
    onSearch(selectedProduct);
  };

  const resetSearchFields = () => {
    setSelectedProduct("");
    onSearch("");
  };

  const showMessage = (
    label: string,
    message: string,
    severity: ToastMessage["severity"]
  ) => {
    toast.current?.show({
      severity: severity,
      summary: label,
      detail: message,
      life: 3000,
    });
  };

  const handleOnSuccess = () => {
    setShowAddProductModal(false);
    showMessage("Add Products", "Product added successfully", "success");
  };

  return (
    <div
      className="col-12"
      style={{ width: "100%", padding: "0px", marginBottom: "0.5rem" }}
    >
      <Toast ref={toast} />
      <div className="card border-noround p-2" style={{ minHeight: "3.5rem" }}>
        <div
          className="input-container  font-bold text-white border-round"
          style={{ float: "left", width: "18%" }}
        >
          <div className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
              placeholder={t("product_search")}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="buttons-container">
          <div 
          className=" filter-button font-bold text-white border-round ">
            <Button
              className=" text-black border-round"
              style={{ width: "100%", textAlign: "center" }}
              onClick={handleSearch}
              severity="success"
            >
              Filter
            </Button>
          </div>
          <div
           className="filter-button font-bold text-white border-round ml-2">
            <Button
              className="text-black border-round"
              style={{ width: "100%", textAlign: "center" }}
              onClick={resetSearchFields}
              severity="secondary"
              text
              raised
            >
              Reset
            </Button>
          </div>
          <div
            className="add-button-container border-round font-bold ml-3 text-primary"
            style={{ textAlign: "right" }}
          >
            <Button
              className="bg-blue-700 border-round p-button p-button-text p-component text-center text-white"
              style={{ textAlign: "center" }}
              onClick={() => setShowAddProductModal(true)}
              severity="secondary"
              text
              raised
            >
              Add Product
            </Button>
          </div>
        </div>
        <Dialog
          visible={showAddProductModal}
          className="quickview w-50"
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          modal
          onHide={() => setShowAddProductModal(false)}
        >
          <ProductInfo
            isUpdate={false}
            handleOnSuccess={handleOnSuccess}
            updateProductInfo={null}
          />
        </Dialog>
      </div>
    </div>
  );
};
export default ProductSearch;
